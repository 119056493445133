<!-- System: STA
    Purpose: In dashboard user can see his stats /progress like time worked ,
            projects , team members etc
-->
<template>
  <v-app>
    <v-container :fluid="true" class="px-sm-16">
      <!-- Date Range header  -->
      <v-row class="mt-1" v-if="dashboard_data.last_working_days != null">
        <v-col cols="12" sm="6" md="4" class="text-left px-sm-4">
          <h4 class="mt-5 todays-progress-style" v-if="CustomeDate">
            {{ dateFormate(start_date) }} - {{ dateFormate(end_date) }}
            {{ " Progress" }}
          </h4>
          <h4 class="mt-5 todays-progress-style" v-else>
            {{
              dashboard_data.last_working_days === getToday()
                ? `Today's`
                : dashboard_data.last_working_days === getYesterDay()
                ? `Yesterday's`
                : `Last Working Day's`
            }}
            {{ " Progress" }}
          </h4>
        </v-col>
        <v-spacer></v-spacer>
        <!-- Date Range Picker  -->
        <v-col cols="12" sm="6" md="3">
          <label
            class="pl-3 labels d-block font-weight-thin text-left date-range-label mt-10 mt-sm-2"
            for
            id="cRange"
            >Date Range:</label
          >

          <v-menu nudge-left="0" :close-on-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-col class="d-flex flex-row-reverse">
                <v-subheader
                  class="selected-date mr-4"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 100%"
                  @click="checkClick"
                >
                  {{
                    dateRangeTitle
                      ? dateRangeTitle
                      : dashboard_data.last_working_days
                  }}
                  <span class="d-flex justify-start ml-auto">
                    <v-icon color="#2758F6" id="rangeBtn"
                      >mdi-calendar-range</v-icon
                    >
                  </span>
                </v-subheader>
              </v-col>
            </template>

            <v-list id="custom-list-style">
              <v-list-item
                style="min-height: 30px"
                v-for="(item, index) in items"
                :key="index"
                @click="checkIfCustomDate(item)"
              >
                <v-list-item-title style="font-size: 13px" class="custom">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-dialog
            ref="dialog"
            v-model="modal_2"
            :return-value.sync="date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-show="(modal_2_show = false)"
                v-model="date"
                label="Picker in dialog"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="closeDateModal()">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                id="apply-btn"
                @click="getDashboardFromCustomDate()"
              >
                Apply
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- Dashboard Widgets  -->
      <v-row class="ml-0 mb-5" v-if="dashboard_data.last_working_days != null">
        <DashboardWidgets
          :end="end_date"
          :start="start_date"
          :widget_data="widget_data"
          :last_working_day="dashboard_data.last_working_days"
        />
      </v-row>
      <v-row class="mt-5" v-if="dashboard_data.last_working_days != null">
        <v-col
          cols="12"
          sm="12"
          md="2"
          v-if="
            dashboard_data.projects.length > 0 &&
            !(
              dashboard_data.projects.length == 1 &&
              dashboard_data.projects[0].name == 'Default Project'
            )
          "
        >
          <h3 class="row-heading text-left">Projects</h3>
        </v-col>
        <v-col cols="12" sm="1" md="1" lg="1"> </v-col>
      </v-row>
      <!-- Projects Card  -->
      <v-row
        v-if="
          dashboard_data.projects &&
          !(
            dashboard_data.projects.length == 1 &&
            dashboard_data.projects[0].name == 'Default Project'
          )
        "
      >
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
          :key="project.id"
          v-for="project in projects"
        >
          <DashboardProject :project="project" />
        </v-col>
      </v-row>
      <!-- Team Members Card  -->
      <v-row v-if="dashboard_data.last_working_days != null">
        <v-col
          cols="12"
          sm="12"
          md="2"
          v-if="dashboard_data.members && dashboard_data.members.length > 0"
        >
          <h3 class="text-left">Team Members</h3>
        </v-col>
      </v-row>
      <v-row v-if="dashboard_data.members && dashboard_data.members.length > 0">
        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="2"
          :key="member.id"
          v-for="member in members"
        >
          <DashboardTeamMember :member="member" />
        </v-col>
      </v-row>
    </v-container>

    <!-- Dummy Dashboards For New User  -->
    <v-container>
      <DummyDashboard
        v-if="
          dummyCondition &&
          dashboard_data.last_working_days == null &&
          selectedCompanyUserStatus != 'employee'
        "
      />
      <DummyDashboardEmployee
        v-if="
          dummyCondition &&
          dashboard_data.last_working_days == null &&
          selectedCompanyUserStatus == 'employee'
        "
      />
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "dashboard",
  components: {
    DashboardWidgets: () => import("@/components/dashboard/DashboardWidgets"),
    DashboardProject: () => import("@/components/dashboard/DashboardProject"),
    DashboardTeamMember: () =>
      import("@/components/dashboard/DashboardTeamMember"),
    DummyDashboard: () => import("@/views/DummyPages/DummyDashboard"),
    DummyDashboardEmployee: () =>
      import("@/views/DummyPages/DummyDashboardEmployee"),
  },
  data() {
    return {
      modal_2: false,
      date_loader: false,
      dummyCondition: false,
      date: [],
      showPicker: false,
      menu: false,
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Last 7 Days", value: "" },
        { id: 4, title: "Last 30 Days", value: "" },
        { id: 5, title: "This Month", value: "" },
        { id: 7, title: "Last Month", value: "" },
        { id: 6, title: "Custom Range" },
      ],
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      start_date: moment().startOf("month").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      filter: "by_projects",
      project_sort: "by_hours",
      member_sort: "by_hours",
      loader: true,
      showChart: false,
      dateRangeTitle: "",
      CustomeDate: false,
    };
  },
  computed: {
    ...mapState("custom", [
      "widget_data",
      "dashboard_data",
      "team_members",
      "companies_list",
      "active_team_members_list",
    ]),
    ...mapState("auth", ["user"]),
    /**
     * Return the selected user status
     * "employee" , "employeer"
     */
    selectedCompanyUserStatus() {
      return this.selectedCompany.user_status;
    },
    selectedCompany() {
      let company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the team member of that user.
     */
    members() {
      let data = Object.assign([], this.dashboard_data.members);
      data.sort((a, b) => {
        let x = 0;
        let y = 0;
        if (this.member_sort === "by_name") {
          x = a.name.toLowerCase();
          y = b.name.toLowerCase();
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        } else if (this.member_sort === "by_hours") {
          x = parseInt(a.all_minutes);
          y = parseInt(b.all_minutes);
        } else if (this.member_sort === "by_projects") {
          x = a.project_count;
          y = b.project_count;
        } else {
          x = a.task_count;
          y = b.task_count;
        }
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
      return data;
    },
    /**
     * Return the projects of that user.
     */
    projects() {
      let data = Object.assign([], this.dashboard_data.projects);
      return data.sort((a, b) => {
        let x = 0;
        let y = 0;
        if (this.project_sort === "by_name") {
          x = a.name.toLowerCase();
          y = b.name.toLowerCase();
          if (x < y) return -1;
          if (x > y) return 1;
          return 0;
        } else if (this.project_sort === "by_hours") {
          x = parseInt(a.all_minutes);
          y = parseInt(b.all_minutes);
        } else if (this.project_sort === "by_employees") {
          x = a.employees;
          y = b.employees;
        } else {
          x = a.tasks_count;
          y = b.tasks_count;
        }
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
    },
  },

  mounted() {
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtn").click();
        x.style.display = "none";
      }
    });
    let company = this.companies_list.find(
      (c) => c.id === this.$route.params.id
    );
    if (company && company.user_status != "employee") {
      let team_members = this.$store.state.custom.get_team_members_list;
      if (team_members.length < 1) {
        this.getTeam();
      }
    }
    this.getActiveTeamMembersList();
    this.getDashboardData();
    setTimeout(() => this.dateRangeLastWorking(), 100);
  },
  methods: {
    closeDateModal() {
      this.modal_2 = false;
      this.menu = false;
    },
    /**
     * This function is resposible for fetching
     * team members list of that user
     */
    getActiveTeamMembersList() {
      if (!this.active_team_members_list.length) {
        this.$store.dispatch("custom/get_active_team_members_list", {
          company_id: this.$route.params.id,
        });
      }
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    /**
     * This function is resposible for custom
     * date range selection
     */
    getDashboardFromCustomDate() {
      this.modal_2 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitle =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
        this.dateRangeTitle =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        this.checkIfStartDateGreater();
      }
      this.date_loader = true;
      this.getDashboardData(this.start_date, this.end_date);
      this.date = "";
      this.menu = false;
    },

    checkIfStartDateGreater() {
      let etmp = "";
      let stmp = "";
      stmp = this.end_date;
      etmp = this.start_date;
      this.start_date = stmp;
      this.end_date = etmp;
    },

    checkIfCustomDate(date, loader_state) {
      if (!loader_state) {
        this.date_loader = true;
      }
      this.dateRangeTitle = moment(date).format("MMM D, YYYY");
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 3) {
        let start = moment().subtract(6, "days").format("MMM D, YYYY");
        let end = moment().format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last 7 Days";
        this.CustomeDate = true;
      }
      if (date.id === 4) {
        let start = moment().subtract(29, "days").format("MMM D, YYYY");
        let end = moment().format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last 30 Days";
        this.CustomeDate = true;
      }
      if (date.id === 5) {
        let start = moment().startOf("month").format("MMM D, YYYY");
        let end = moment().endOf("month").format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "This Month";
        this.CustomeDate = true;
      }
      if (date.id === 7) {
        let start = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("MMM D, YYYY");
        let end = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("MMM D, YYYY");
        date.value = start + "-" + end;
        this.dateRangeTitle = "Last Month";
        this.CustomeDate = true;
      }
      if (date.id === 6) {
        this.modal_2 = true;
        document.getElementById("cRange").click();
        this.CustomeDate = true;
      } else {
        this.showPicker = false;
        this.setDates(date);
        this.getDashboardData(this.start_date, this.end_date);
      }
      document.getElementById("cRange").click();
    },

    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 5) {
        this.start_date = moment().startOf("month").format("MMM D, YYYY");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      } else if (date.id === 7) {
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("MMM D, YYYY");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("MMM D, YYYY");
      }
    },

    dateFormate(date) {
      return moment(date).format("MMM Do, YYYY");
    },

    dateRangeLastWorking() {
      let date = this.$store.state.custom.dashboard_data.last_working_days;
      if (date != null) {
        let today = new Date().toISOString().slice(0, 10);
        let days = this.caculateDays(date, today);
        this.start = moment().subtract(days, "day");
        this.end = moment().subtract(days, "day");
        this.start_date = this.start.format("YYYY-MM-DD");
        this.end_date = this.end.format("YYYY-MM-DD");
        this.checkIfCustomDate(date, true);
      }
    },

    getToday() {
      return new Date().toISOString().slice(0, 10);
    },

    getYesterDay() {
      var prev_date = new Date();
      prev_date.setDate(prev_date.getDate() - 1);
      return prev_date.toISOString().slice(0, 10);
    },

    caculateDays(date1, date2) {
      var start = moment(date1);
      var end = moment(date2);
      let days = end.diff(start, "days");
      return days;
    },

    /**
     * This function is resposible for fetching
     * team members list of that user
     */
    getTeam() {
      this.$store.dispatch("custom/get_team_members", {
        company_id: this.$route.params.id,
      });
    },

    /**
     * This function is resposible for fetching
     * Dashboard data e.g widgets etc
     */
    getDashboardData(start_date = null, end_date = null) {
      if (
        (this.$store.state.custom.dashboard_data.last_working_days == null &&
          this.$store.state.custom.dashboard_data.members.length == 0) ||
        this.date_loader == true
      ) {
        this.$store.commit("custom/toggle_loader", true);
      }
      let data = {
        company_id: this.$route.params.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        start_date: start_date,
        end_date: end_date,
      };
      if (data.company_id !== null)
        this.$store
          .dispatch("custom/getDashboardData", data)
          .then(() => {
            this.date_loader = false;
            this.dummyCondition = true;
          })
          .catch(() => {
            this.date_loader = false;
          });
      else
        this.$store.dispatch("custom/getAllCompaniesList").then(() => {
          this.getDashboardData();
        });
    },
  },
};
</script>

<style scoped>
.dropdown-menu-borders {
  box-sizing: border-box;
  height: 41px;
  width: 369px;
  border: 1px solid rgba(54, 69, 79, 0.32);
  border-radius: 4px;
  background-color: #ffffff;
}
.row-heading {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
}
.todays-progress-style {
  height: 28px;
  color: #36454f;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px;
}
.calender-btn {
  width: 100% !important;
  z-index: 0.9 !important;
  align-content: right !important;
  margin-left: -0px;
}
.labels {
  margin-top: 20px;
  margin-bottom: -5px;
  color: #36454f;
  font-family: "Poppins", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}
.selected-date {
  border: 1px solid rgba(54, 69, 79, 0.32) !important;
  font-size: 12px !important;
  display: flex !important;
  height: 35px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
#custom-list-style .v-list-item__title {
  font-size: 12px !important;
}

#dashboard-calender span.v-btn__content i {
  padding: 19px 0 !important;
}
#apply-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  font-family: "Poppins" !important;
}
@media (max-width: 1700px) and (min-width: 1600px) {
  #dashboard-calender span.v-btn__content i {
    height: 36px;
  }
}
</style>
